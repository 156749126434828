import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Front} from "./views/Front";

function Application() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Front/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Application;
