import React from "react";
import "../../css/vars.css";
import "./SocialButton.css";

interface SocialButtonProps {
    name: string;
    url: string;
}

export const SocialButton: React.FC<SocialButtonProps> = ({name, url}) => {
    return (
        <a className="socialButton" href={url}>
            <div className="socialButton__container">
                {name}
            </div>
            <div className="socialButton__bar"/>
        </a>
    );
};