import React from "react";
import "../css/size.css";
import "../css/text.css";
import {FlexCenterColumn} from "../components/layout/FlexCenter";
import {ButtonBar} from "../components/layout/ButtonBar";

export const Front: React.FC = () => {
    return (
        <main className="width height">
            <FlexCenterColumn className="width height">
                <div className="header">
                    kuiper.rocks
                </div>
                <ButtonBar/>
            </FlexCenterColumn>
        </main>
    );
};