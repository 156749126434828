import React from "react";
import "./FlexCenter.css";
import "./ButtonBar.css";
import {SocialButton} from "../widgets/SocialButton";

export const ButtonBar: React.FC = () => {
    return (
        <div className="flex buttonBar">
            <div>
                <SocialButton name="Twitter (Main)" url="https://twitter.com/ZestyLemonss"/>
            </div>
            <div>
                <SocialButton name="Twitter (Spam)" url="https://twitter.com/realcatirl"/>
            </div>
        </div>
    );
};