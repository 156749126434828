import React, {ReactElement} from "react";
import "./FlexCenter.css"

interface FlexCenterProps {
    children: ReactElement[] | ReactElement | string;
    className?: string;
}

export const FlexCenter: React.FC<FlexCenterProps> = ({children, className}) => {
    return (
        <div className={"flex " + className}>
            {children}
        </div>
    )
}

export const FlexCenterColumn: React.FC<FlexCenterProps> = ({children, className}) => {
    return (
        <div className={"flex__build " + className}>
            {children}
        </div>
    )
}